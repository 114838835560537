import { ToReserveList } from '@/api/types/home';
import { useTranslation } from 'next-i18next';
import { FC, useEffect } from 'react';
import { SubjectTypeEnum } from '../interface';
import useReportForGs2User from '@/utils/hooks/useReportForGs2User';
import avatorDefault from '@public/imgs/default-teacher.svg';
import CloseShadow from '@/components/Icon/closeShadow';
import router from 'next/router';
import { trace } from '@wk/wk-gatherer';
import classNames from 'classnames';

const subjectMap = {
  [`${SubjectTypeEnum.ENGLISH}_EN`]: { value: 'English', teacherName: 'English teacher' },
  [`${SubjectTypeEnum.CHINESE}_EN`]: { value: 'Chinese', teacherName: 'Chinese Language Instructor' },
  [`${SubjectTypeEnum.MATH}_EN`]: { value: 'Math', teacherName: 'Math teacher' },
  [`${SubjectTypeEnum.ENGLISH}_ZH`]: { value: '英文', teacherName: '英文老师' },
  [`${SubjectTypeEnum.CHINESE}_ZH`]: { value: '中文', teacherName: '中文授课老师' },
  [`${SubjectTypeEnum.MATH}_ZH`]: { value: '数学', teacherName: '数学老师' },
};

interface Props extends ToReserveList {
  removeBookingCourse?: (uuid: string) => void;
  source: string;
  isHome?: boolean;
}

const MyLecture: FC<Props> = ({
  data,
  subject,
  courseId,
  teacherHeadImage,
  teacherName,
  teacherNameEn,
  guidanceText,
  guidanceTextEn,
  removeBookingCourse,
  uuid,
  hideCloseButton,
  source,
  subscribeStudent,
  isHome = false,
}) => {
  const { t, i18n } = useTranslation('home');
  const lang = i18n.language.toLocaleUpperCase();
  const reportGs2Params = useReportForGs2User();

  useEffect(() => {
    if (Object.keys(reportGs2Params).length === 0) {
      return;
    }
  }, [reportGs2Params]);

  const toReservePage = () => {
    trace('C_SelectedClass_Entrance', {
      source,
      classType: data.classType === 'PRIVATE' ? '私教课' : '班课',
      courseId,
      subject,
      studentType: subscribeStudent ? '订阅' : '常规',
    });
    router.push({
      pathname: data.classType === 'PRIVATE' ? '/home/bookClass/private' : '/home/bookClass',
      query: {
        subject,
        courseType: { PRIVATE: 'private', GROUP: 'normal' }[data.classType],
      },
    });
  };

  return (
    <div
      data-expose-event="D_SelectedClass_Entrance"
      data-expose-data={JSON.stringify({
        source,
        classType: data.classType === 'PRIVATE' ? '私教课' : '班课',
        courseId,
        subject,
        studentType: subscribeStudent ? '订阅' : '常规',
      })}
      className={classNames(
        'relative flex items-center justify-between rounded-[8px] text-[wk-words-6]',
        isHome ? 'border-0  p-[24px] pb-0 last:pb-[24px]' : 'mb-[16px] bg-[#F5F5F5] p-[16px] last:mb-0',
      )}
    >
      {hideCloseButton === false && !!removeBookingCourse && (
        <CloseShadow
          onClick={() => {
            trace('C_SelectedClass_EntranceClose', {
              classType: data.classType === 'PRIVATE' ? '私教课' : '班课',
              courseId,
              subject,
            });
            removeBookingCourse(uuid);
          }}
          className="absolute right-[8px] top-[8px] cursor-pointer"
        />
      )}
      <div className="flex max-w-[calc(100%-200px)] items-center break-words">
        <img
          src={teacherHeadImage || avatorDefault.src}
          alt="WuKong"
          className="h-[60px] w-[60px] rounded-[6px] border-[1px] border-solid border-[rgba(0,0,0,0.06)]"
        />
        <div className="ml-[16px]">
          <h3 className="line-clamp-1 text-[20px] font-[800] leading-[24px]">
            {lang === 'ZH'
              ? teacherName || subjectMap[`${subject}_${lang}` as keyof typeof subjectMap].teacherName
              : teacherNameEn || subjectMap[`${subject}_${lang}` as keyof typeof subjectMap].teacherName}
          </h3>
          <p className="mt-[12px] line-clamp-1 flex items-center text-[14px]">
            {lang === 'ZH' ? guidanceText : guidanceTextEn}
          </p>
        </div>
      </div>
      <span
        onClick={toReservePage}
        className={classNames(
          'box-border flex h-[40px] cursor-pointer items-center rounded-[200px] px-[24px] text-[14px] font-[600]',
          isHome
            ? 'border-[1.5px] border-solid border-[#4D4D4D] bg-white text-wk-words-1'
            : 'absolute bottom-[16px] right-[32px] bg-[#CD292A] text-[#fff]',
        )}
      >
        {t('自主选课-立即约课')}
      </span>
    </div>
  );
};

export default MyLecture;
