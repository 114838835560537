import qs from 'query-string';

interface QueryParams {
  [key: string]: string | string[] | undefined;
}

/**
 * 如果是 edu.net 的域名，返回 edu.net 的 API地址
 * @returns
 */
export const getAPIBaseUrl = (api: string | undefined) => {
  if (!api) {
    return '';
  }
  const isEduUrl = globalThis?.location?.href?.includes?.('.wukongedu.net');
  return isEduUrl ? api.replace('.wukongacademy.com', '.wukongedu.net') : api;
};

/**
 * 去除url上的某个查询参数
 * @param url
 * @param param
 * @returns
 */
export const removeQueryParam = (url: string, param: string) => {
  const parsedUrl = new URL(url);
  parsedUrl.searchParams.delete(param);
  return parsedUrl.toString();
};

/**
 * 解析 URL 查询参数
 * @param url - 可选的 URL 字符串，如果不提供则使用当前页面 URL
 * @returns 解析后的查询参数对象
 */
export const getQueryParams = (url?: string): QueryParams => {
  try {
    // 如果提供了 URL，使用 URL 对象解析
    if (url) {
      const urlObj = new URL(url);
      return qs.parse(urlObj.search.slice(1)) as QueryParams;
    }

    // 在浏览器环境下使用 window.location
    if (typeof window !== 'undefined') {
      return qs.parse(window.location.search.slice(1)) as QueryParams;
    }

    return {};
  } catch (error) {
    console.error('解析 URL 参数失败:', error);
    return {};
  }
};
