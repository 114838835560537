import useUser from '@/store/useUser';
import { useEffect } from 'react';
import { getQueryParams, removeQueryParam } from '../url';
import { switchStudent } from '@/api/login';
import { setTokenInfo } from '../helpers';
import { useRouter } from 'next/router';

const useSwitchStudent = () => {
  const router = useRouter();
  const { curStudent, setCurStudent, students } = useUser((store) => ({
    setCurStudent: store.updateCurStudent,
    curStudent: store.curStudent,
    students: store.students,
  }));

  const reloadPage = () => {
    const url = removeQueryParam(window.location.href, 'studentId');
    router.replace(url);
  };

  /**
   * 根据URL上的学生id切换学生
   */
  useEffect(() => {
    const { studentId } = getQueryParams();
    if (!studentId || !students?.length || !curStudent?.uuid) {
      return;
    }
    const urlStudent = students?.find((item) => item.uuid === studentId);
    if (!urlStudent?.uuid || curStudent?.uuid === urlStudent?.uuid) {
      reloadPage();
      return;
    }
    switchStudent(urlStudent?.uuid).then(({ content }) => {
      const { refreshToken, token } = content || {};
      setTokenInfo(token, refreshToken);
      setCurStudent(urlStudent);
      reloadPage();
    });
  }, [students, curStudent]);
};

export default useSwitchStudent;
